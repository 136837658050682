const CARDS = [
  {
    cardId: 1,
    img: require("../assets/images/1.jpg"),
  },
  {
    cardId: 2,
    img: require("../assets/images/2.jpg"),
  },
  {
    cardId: 3,
    img: require("../assets/images/3.jpg"),
  },
  {
    cardId: 4,
    img: require("../assets/images/4.jpg"),
  },
  {
    cardId: 5,
    img: require("../assets/images/5.jpg"),
  },
  {
    cardId: 6,
    img: require("../assets/images/6.jpg"),
  },
  {
    cardId: 7,
    img: require("../assets/images/7.jpg"),
  },
  {
    cardId: 8,
    img: require("../assets/images/8.jpg"),
  },
];

export { CARDS };
